body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hedd {
    background-image: url('./img/lineLogo.png');
    background-position-x: 50%;
    background-position-y: bottom;
    background-size: auto;
    background-repeat: no-repeat;

    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    z-index: 1;
    position: relative;
}


.logo img {
    width: 19em;
}

.homePage {
    background-image: url('./img/lineLogodown.png');
    background-position-x: 50%;
    background-size: auto;
    background-repeat: no-repeat;

    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    z-index: 1;
    padding-top: 5em;
    max-width: 100%;
    position: relative;
}

.telIcon {
    width: 25px;
}

.telLink {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
}

.headerBack {
    width: fit-content;
    min-height: 0;
    max-width: 100%;
    z-index: -1;
    position: relative;
    top: -60px;
    margin-bottom: -143px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.headerBack img {
    z-index: auto;
    max-width: 100%;
    min-width: 100%;
    min-height: 300px;
    width: fit-content;
    object-fit: cover;

}

a {
    color: green;
    text-decoration: none;
}

a:hover {
    color: black;
}


table {
    border: 1px solid #eee;
    table-layout: fixed;
    margin-bottom: 20px;
}

table th {
    font-weight: bold;
    padding: 5px;
    background: #efefef;
    border: 1px solid #dddddd;

}

table td {
    padding: 5px 10px;
    border: 1px solid #eee;
    text-align: left;
    text-align: center;

}

table tbody tr:nth-child(odd) {
    background: #fff;
}

table tbody tr:nth-child(even) {
    background: #F7F7F7;
}
